import { notification } from "antd";
import { useEffect, useState } from "react";
// import "./styles.scss";
import routesConstant from "../../../routes/routesConstant";
import { useNavigate } from "react-router-dom";
import uathApi from "../../../api/auth.api";
import {
  setStorageRefreshToken,
  setStorageToken,
  setStorageUserDetail,
  setUserAnalytics,
} from "../../../utils/storage";
import {
  getAgreeWithTermAndConditions,
  getRememberLoginInfo,
  getShouldRememberPassword,
  getUser,
  setAgreeWithTermAndConditions,
  setInitializedUser,
  setRememberLoginInfo,
  setShouldRememberPassword,
  setUser,
  setUserAccessToken,
  setUserRefreshToken,
} from "../../../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { decodeUrl, getDefaultHomePage } from "../../../utils/util";

import LOGO from "../../../assets/images/LOGO.svg";

import ReactGA from "react-ga4";

import AuthBackground from "../../../assets/images/AuthBackground.png";

import LoginFooterImage1 from "../../../assets/images/login/LoginFooterImage1.png";
import LoginFooterImage2 from "../../../assets/images/login/LoginFooterImage2.png";
import LoginFooterImage3 from "../../../assets/images/login/LoginFooterImage3.png";
import LoginFooterImage4 from "../../../assets/images/login/LoginFooterImage4.png";
import LoginFooterImage5 from "../../../assets/images/login/LoginFooterImage5.png";
import LoginFooterImage6 from "../../../assets/images/login/LoginFooterImage6.png";

import authApi from "../../../api/auth.api";
import "./login.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import { userApi } from "../../../api/user.api";
import GoogleButton from "../../../components/googleButton/GoogleButton";
import { ring } from "ldrs";

import RetailerAuthFooter from "../../../components/retailerComponents/retailerAuthFooter/RetailerAuthFooter";
const Login = () => {
  ring.register();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shouldRememberPassword = useSelector(getShouldRememberPassword);
  const rememberLoginInfo = useSelector(getRememberLoginInfo);

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format") // Ensures the value is a valid email address
      .required("Email is required") // Makes the email field required
      .min(5, "Email must be at least 5 characters") // Minimum length check
      .max(50, "Email must be less than 50 characters") // Maximum length check
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email format is incorrect")
      .required(), // Custom pattern check
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: rememberLoginInfo?.email || "",
      password: rememberLoginInfo?.password || "",
    },
    validationSchema,
    onSubmit: (values) => {
      //  e.preventDefault()
      handleLogin(values);
    },
  });

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/login", title: "Login" });
  }, []);

  const handleLogin = (formData) => {
    setIsLoading(true);
    uathApi
      .login(formData)
      .then((data) => {
        if (shouldRememberPassword) {
          dispatch(setRememberLoginInfo(formData));
        } else {
          dispatch(setRememberLoginInfo({ email: "", password: "" }));
        }
        setIsLoading(false);
        onLoginSuccess(data);
      })
      .catch((err) => {
        notification.error({
          message:
            err?.response?.data?.message || "Incorrect email or password",
        });
        setIsLoading(false);
      });
  };

  const onLoginSuccess = async (data) => {
    console.log(data, "nvczgdchdvfdh");

    if (data.user.role === "guest") {
      if (data?.user.role && data?.user?.userId) {
        console.log(data?.user);

        setStorageUserDetail(data.user);
        dispatch(setUser(data.user));
        dispatch(setUserAccessToken(data.tokens.access.token));
        dispatch(setUserRefreshToken(data.tokens.refresh.token));
        setStorageToken(data.tokens.access.token);
        setStorageRefreshToken(data.tokens.refresh.token);
        // const formData = { userId: data.user.id, device_type: getDeviceType() };
        // await userApi
        //   .initializeUser(formData)
        //   .then((response) => {
        //     if (response.status) {
        //       dispatch(setInitializedUser(response.data));
        //       setUserAnalytics(response.data);
        //     } else {
        //       notification.error({ message: response.data.message });
        //     }
        //   })
        //   .catch((error) => {
        //     notification.error({ message: "Failed to initialize the user" });
        //   });

        notification.success({ message: "Login success" });
        const returnUrl = new URLSearchParams(window.location.search).get(
          "returnUrl"
        );
        if (returnUrl) {
          window.location = decodeUrl(returnUrl);
        } else {
          navigate(getDefaultHomePage(data.user, true));
        }
      }
    } else {
      setStorageUserDetail(data.user);
      dispatch(setUser(data.user));
      console.log(data.user, "UUUUUUUUUUUUUU");

      setStorageToken(data.tokens.access.token);
      setStorageRefreshToken(data.tokens.refresh.token);

      const formData = { userId: data.user.id, device_type: getDeviceType() };
      await userApi
        .initializeUser(formData)
        .then((response) => {
          if (response.status) {
            dispatch(setInitializedUser(response.data));
            setUserAnalytics(response.data);
          } else {
            notification.error({ message: response.data.message });
          }
        })
        .catch((error) => {
          notification.error({ message: "Failed to initialize the user" });
        });

      notification.success({ message: "Login success" });
      const returnUrl = new URLSearchParams(window.location.search).get(
        "returnUrl"
      );
      if (returnUrl) {
        window.location = decodeUrl(returnUrl);
      } else {
        navigate(getDefaultHomePage(data.user, true));
      }
    }
  };

  const getDeviceType = () => {
    const width = window.innerWidth;
    if (width <= 768) return "Mobile";
    else if (width <= 1024) return "Tablet";
    return "Desktop";
  };
  const handleGuestUser = async (e) => {
    try {
      e.preventDefault();
      const response = await authApi.guestUserLogin();

      console.log(response, "Guest user response");
      onLoginSuccess(response);
    } catch (error) {
      notification.error({ message: "error.message" });
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="sm:px-[32px] md:px-[73px] min-h-screen lg:min-h-[920px] lg:h-lg:min-h-screen 3xl:min-h-screen  flex justify-center items-center lg:items-baseline pt-16 3xl:pt-[8%]   w-full relative">
        <div
          style={{
            backgroundImage: `url(${AuthBackground})`,
            backgroundSize: "cover",
          }}
          className={`image-wrapper`}
        >
          <div className="mx-auto pt-[7%] pb-[15%] sm:pb-0 sm:pl-10 sm:pt-10 w-full ">
            <img
              src={LOGO}
              alt=""
              className="h-8 mx-auto sm:mx-0 sm:h-[33px]"
            />
          </div>
          <div className=" hidden lg:block">
            <RetailerAuthFooter />
          </div>
        </div>

        <div className="w-full log-box h-[90vh] sm:h-fit xl:h-2/3 sm:w-2/3 lg:w-2/5 bg-[#060606] rounded-[9px] sm:rounded-[16px] overflow-hidden absolute bottom-0 sm:relative z-10">
          <div className="flex min-h-full flex-col justify-center px-6 pt-4 sm:mb-4 lg:px-8 ">
            {/* <div className=" justify-end hidden sm:flex cursor-pointer" >
              <div className="flex rounded-md  w-fit" onClick={(e) => handleGuestUser(e)}>
                <FiUser className="text-gray-500  pt-1" size={15} />
                <span className="text-gray-500 hover:text-slate-400 text-[11px]"> Continue as Guest</span>
              </div>
            </div> */}
            <div className="sm:mx-auto mt-[7%] sm:mt-0 sm:w-full sm:max-w-sm ">
              <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-white">
                Login
              </h2>
              <p className="text-center text-gray-300">
                Log in to your Metadrob account and continue enjoying 3D
                shopping experience
              </p>
            </div>

            <div className="mt-10  sm:mx-auto sm:w-full sm:max-w-sm ">
              <form className="" onSubmit={formik.handleSubmit}>
                <div>
                  <label
                    for="email"
                    className="block text-sm font-medium leading-6 text-slate-200 text-left"
                  >
                    Email address
                  </label>
                  <div className="mt-2 text-left">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      placeholder="Enter email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="email-inp block w-full rounded-md border-0 pr-6 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inse sm:text-sm sm:leading-6 outline-none pl-3"
                    />

                    {formik.touched.email && formik.errors.email ? (
                      <span className="text-red-400 text-left text-[10px] font-light">
                        {formik.errors.email}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={
                    formik.touched.email && formik.errors.email
                      ? `mt-0`
                      : "mt-3"
                  }
                >
                  <label
                    for="email"
                    className="block text-sm font-medium leading-6 text-slate-200 text-left"
                  >
                    Password
                  </label>
                  <div className=" mt-2 text-left relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="email-inp block w-full mr-8 pr-16 rounded-md  border-0 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inse sm:text-sm sm:leading-6 outline-none pl-3"
                      // className="block w-full rounded-[5px] bg-[#181818] py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:text-[#7A7F84] focus:ring-2 focus:ring-inset"
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-2.5 cursor-pointer text-white "
                    >
                      {showPassword ? "Hide" : "Show"}
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <span className="text-red-400 text-left text-[10px] font-light">
                        {formik.errors.password}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={
                    formik.touched.password && formik.errors.password
                      ? "mt-0 flex items-center justify-between"
                      : "flex mt-3 items-center justify-between"
                  }
                >
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 font-normal block text-[12px] text-[#00f6ff]"
                    >
                      Remember me
                    </label>
                  </div>
                  <div className="text-[12px">
                    <p
                      onClick={() => navigate("/forgot-password")}
                      className="font-normal cursor-pointer text-[#00f6ff] hover:text-[#57f9ff]"
                    >
                      Forgot password?
                    </p>
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={
                      isLoading ||
                      formik.values.email == "" ||
                      formik.values.password == ""
                    }
                    className={` ${
                      isLoading &&
                      "disabled:bg-white disabled:text-slate-950 flex justify-center"
                    }flex w-full disabled:bg-[#383838] disabled:text-[#808080] sm:w-5/6 mx-auto justify-center rounded-md bg-slate-50 px-3 py-1.5 text-sm font-semibold leading-6 text-slate-950 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    {isLoading ? (
                      <div className="flex">
                        <l-ring
                          size="19"
                          stroke="2"
                          bg-opacity="0"
                          speed="2"
                          color="black"
                        ></l-ring>{" "}
                        <span className="ml-2">Loading </span>
                      </div>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>
              <div className=" mt-[8%] xs:mt-3">
                <span className="px-2 text-gray-500">Or continue with</span>
                <div className="flex mx-auto gap-4 justify-center my-2">
                  {/* <div className="object-fill ">
                    <img src={whatsappLogo} className="w-7 "></img>
                  </div>
                  <div className="object-fill ">
                    <img src={appleLogo} className="w-7 "></img>
                  </div> */}
                  <div className="object-fill cursor-pointer">
                    <GoogleButton
                      onSuccess={(data) => {
                        onLoginSuccess(data);
                      }}
                    />
                    {/* <img src={googleLogo} className="w-7"></img> */}
                  </div>
                </div>
              </div>

              {/* <div className=" justify-end sm:hidden my-2 cursor-pointer" >
                <div className="flex rounded-md  mx-auto w-fit" >
                  <FiUser className="text-gray-500  pt-1" size={15} />
                  <span className="text-gray-500 hover:text-slate-400 text-[11px]"> Continue as Guest</span>
                </div>
              </div> */}

              <p className="mt-3 text-center text-xs text-gray-500">
                Don`t have an account?
                {/* <span onClick={()=>navigate('/register')} href="#" className="font-semibold leading-6 underline text-slate-50 hover:text-slate-100"> Create new account.</span> */}
                <span
                  href="#"
                  className="font-semibold leading-6 underline text-slate-50 hover:text-slate-100 cursor-pointer"
                  onClick={() => navigate("/register")}
                >
                  {" "}
                  Create new account.
                </span>
              </p>
            </div>
            <div className="mt-auto">
              <hr className="w-full opacity-20 mt-3" />
              <p className="text-xs text-center mt-2 lg:my-3 text-gray-600">
                The most innovative companies supported metadrob
              </p>

              <footer className="flex footer-com flex-col items-center text-centerx mt-auto sm:mt-0">
                <div className="container">
                  <div className="flex justify-evenly">
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage1}
                        className=" rounded-md w-10 md:w-16 object-contain"
                        alt=""
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage2}
                        className=" rounded-md w-8 md:w-10 object-contain"
                        alt=""
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage3}
                        className=" rounded-md w-9 md:w-12 object-contain"
                        alt=""
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage4}
                        className=" rounded-md w-10 md:w-16 object-contain"
                        alt=""
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex ">
                      <img
                        src={LoginFooterImage5}
                        className=" rounded-md w-9 md:w-12  object-contain "
                        alt=""
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage6}
                        className=" rounded-md w-9 md:w-12 object-contain "
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
        {/* <div className=" border" >
            <RetailerAuthFooter />
          </div> */}
      </div>
    </>
  );
};

export default Login;
